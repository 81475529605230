<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>
        <span>交易列表</span>
        <v-spacer />
        <v-text-field
          label="输入地址、高度、交易哈希"
          append-icon="mdi-magnify"
          v-model="search"
          :rules="[rule]"
          @click:append="onSearch"
          @keydown.enter="onSearch"
        />
      </v-card-title>
      <v-data-table
        disable-sort
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        @update:page="onPagination"
        class="elevation-1"
      >
        <template v-slot:[`item.tx_hash`]="{ item }">
          <RippleLink :to="`/transactions/${item.tx_hash}`">
            <Ellipsis :width="width">
              {{ item.tx_hash }}
            </Ellipsis>
          </RippleLink>
        </template>
        <template v-slot:[`item.from`]="{ item }">
          <RippleLink :to="`/address/${item.from}`">
            <Ellipsis :width="width">
              {{ item.from }}
            </Ellipsis>
          </RippleLink>
        </template>
        <template v-slot:[`item.to`]="{ item }">
          <RippleLink :to="`/address/${item.to}`">
            <Ellipsis :width="width">
              {{ item.to }}
            </Ellipsis>
          </RippleLink>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :small="true" :color="state[item.status]" outlined>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getTransactionName(item) }}
        </template>
        <template v-slot:[`item.block_number`]="{ item }">
          <RippleLink :to="`/blocks/${item.block_number}`">
            {{ item.block_number }}
          </RippleLink>
        </template>
        <template v-slot:[`item.pended_at`]="{ item }">
          {{ YMDHms(item.pended_at) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Transaction } from '@/api';
import { YMDHms, valid, getTransactionName } from '@/utils';

export default {
  name: 'Transactions',
  components: { RippleLink, Ellipsis },
  data: () => ({
    state: { SUCCESS: 'green', FAIL: 'red', PENDING: 'orange', NOTFOUND: '' },
    width: '240px',
    search: '',
    rule: (_) =>
      !!!_ ||
      valid.isNumber(_) ||
      valid.isAddress(_) ||
      valid.isTxHash(_) ||
      '无效的输入值',
    table: {
      headers: [
        { text: '交易哈希', value: 'tx_hash' },
        { text: '交易发起者', value: 'from' },
        { text: '交易接收者', value: 'to' },
        { text: '交易状态', value: 'status' },
        { text: '交易类型', value: 'type' },
        { text: '区块高度', value: 'block_number' },
        { text: '交易时间', value: 'pended_at' },
      ],
      items: [],
      total: 0,
    },
  }),
  mounted() {
    this.search = this.$route.query.hash;
    this.getTransactions(1);
  },
  methods: {
    YMDHms,
    getTransactionName,
    getTransactions(page) {
      const _ = this.search;
      let conditions = [];
      if (valid.isAddress(_)) {
        conditions = conditions.concat([
          { wallet_address: _ },
          { contract_addresses: [_] },
        ]);
      }
      if (valid.isNumber(_)) {
        conditions.push({ block_numbers: [_] });
      }
      Transaction.describeTradingRecords({
        limit: 10,
        offset: (page - 1) * 10,
        conditions,
      }).then((res) => {
        this.table = {
          ...this.table,
          items: res.trading_records,
          total: res.total_count,
        };
      });
    },
    onPagination(page) {
      this.getTransactions(page);
    },
    onSearch() {
      const _ = this.search;

      if (!!!_ || valid.isNumber(_) || valid.isAddress(_)) {
        this.getTransactions(1);
        return;
      }
      if (valid.isTxHash(_)) {
        this.$router.push(`/transactions/${_}`);
        return;
      }
    },
  },
};
</script>
