var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("交易列表")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"输入地址、高度、交易哈希","append-icon":"mdi-magnify","rules":[_vm.rule]},on:{"click:append":_vm.onSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","headers":_vm.table.headers,"items":_vm.table.items,"server-items-length":_vm.table.total},on:{"update:page":_vm.onPagination},scopedSlots:_vm._u([{key:"item.tx_hash",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/transactions/" + (item.tx_hash))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.tx_hash)+" ")])],1)]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.from))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.from)+" ")])],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.to))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.to)+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":true,"color":_vm.state[item.status],"outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransactionName(item))+" ")]}},{key:"item.block_number",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/blocks/" + (item.block_number))}},[_vm._v(" "+_vm._s(item.block_number)+" ")])]}},{key:"item.pended_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.YMDHms(item.pended_at))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }